import {
  Box,
  FlexboxProps,
  Grid,
  GridItem,
  HStack,
  Spinner,
} from '@chakra-ui/react';
import * as dateFns from 'date-fns';
import { enCA, fr, frCA } from 'date-fns/locale';
import _ from 'lodash';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import {
  FwButton,
  FwInput,
  FwParagraph,
  useFwStore,
  useFwTheme,
} from 'components/base';
import { getViewDate } from 'components/base/containers/mask/FwMask.helpers';
import { getDateRangeCriteria } from 'components/tables/helpers/tableCriteriaHelpers';
import { Option } from 'core/model';
import { FwMaskCommonProps } from 'core/model/props/FwMask.props';
import { FIELD_TYPE, LOCALES } from 'core/utils/constant';
import {
  dateFormats,
  getCulture,
  isSameDay,
  jsDateFromString,
  jsDateToString,
} from 'core/utils/date';
import utils from 'core/utils/utils';

import EventWrapper from './FwMask.Agenda.EventWrapper';
import {
  computeDateRange,
  formatView,
  getDateLabel,
  maskRowsToEvents,
  messagesFrench,
  prevnext,
  views,
} from './FwMask.Agenda.helpers';

const bigCalendarLocales = { fr, 'fr-CA': frCA, 'en-CA': enCA };
const localizer = dateFnsLocalizer({ ...dateFns, locales: bigCalendarLocales });
const { WEEK, MONTH } = Views;

const FwAgenda: FC<FwMaskCommonProps> = ({
  loading,
  maskStructure,
  maskRows,
  updateCriteriaFunc,
  readOnlyFilterKeys,
  processes,
  handleOpen,
  handleProcessActionClick,
}) => {
  const { t } = useTranslation();
  const {
    accent,
    borderColor,
    _active: { bg: fillColor },
    _disabled: { bg: headerBg },
  } = useFwTheme();
  const { moduleStore, pageStore, pageContentStore } = useFwStore();

  // prepare data
  const { langCode: language } = getCulture();
  const {
    view: {
      date: initDateString,
      type,
      start: hourMin,
      end: hourMax,
      slots: timeSlots,
      step,
    },
    document: { start, end },
  } = maskStructure;
  const agendaFieldKeyFrom = start?.[0];
  const agendaFieldKeyTo = end?.[0];
  const formattedView = formatView(type);
  let min, max;
  let slots = timeSlots;

  if (hourMin && hourMax) {
    min = new Date();
    max = new Date();

    min.setHours(hourMin, 0, 0, 0);
    max.setHours(hourMax, 0, 0, 0);

    slots = slots || hourMax - hourMin + 1;
  }

  // define states
  const [events, setEvents] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedView, setSelectedView] = useState(formattedView);
  const [selectedDate, setSelectedDate] = useState(
    getViewDate(initDateString, { moduleStore, pageStore, pageContentStore })
  );

  const handleSetDateRange = useCallback(
    (date: Date, view: string | number | symbol) => {
      // determine whether view bounds have changed
      const { rangeString: oldDateRange } = computeDateRange(
        selectedDate,
        selectedView
      );
      const { rangeString } = computeDateRange(date, view);

      if (oldDateRange !== rangeString) {
        const dateCriteria = getDateRangeCriteria(
          {
            start: [agendaFieldKeyFrom],
            end: [agendaFieldKeyTo],
          },
          rangeString.split('|')
        );

        // trigger callback to update table query
        updateCriteriaFunc(dateCriteria);
      }
    },
    [
      agendaFieldKeyFrom,
      agendaFieldKeyTo,
      selectedDate,
      selectedView,
      updateCriteriaFunc,
    ]
  );

  const handleChangeDate = useCallback(
    (newDate: Date) => {
      setSelectedDate(newDate);
      handleSetDateRange(newDate, selectedView);
    },
    [handleSetDateRange, selectedView]
  );

  useEffect(() => {
    if (maskStructure && maskRows) {
      const { events, resources } = maskRowsToEvents(
        maskStructure,
        maskRows,
        t
      );
      setEvents(events);
      setResources(resources);
    }
  }, [maskStructure, maskRows, t]);

  useEffect(() => {
    if (pageStore?.filterData) {
      _.forOwn(pageStore?.filterData, (value, key) => {
        if (key === agendaFieldKeyFrom) {
          const newSelectedDate = jsDateFromString(value, dateFormats.isoDate);

          if (!isSameDay(newSelectedDate, selectedDate)) {
            handleChangeDate(newSelectedDate);
          }
        } else {
          // filter with key value
          updateCriteriaFunc([{ key, value }]);
        }
      });
    }
  }, [
    agendaFieldKeyFrom,
    handleChangeDate,
    pageStore,
    selectedDate,
    updateCriteriaFunc,
  ]);

  // define functions
  const handleNavigate = (date: Date, view: keyof Views) => {
    handleSetDateRange(date, view);
    setSelectedDate(date);
    setSelectedView(view);
  };

  const handleChangeView = (view: string) => {
    handleSetDateRange(selectedDate, view);
    setSelectedView(view);
  };

  const onChangeDate = (
    _e: ChangeEvent,
    { value }: { name: string; value: string; fillData?: object }
  ) => {
    handleChangeDate(jsDateFromString(value, dateFormats.isoDate));
  };

  const onPrevious = () => {
    handleChangeDate(prevnext(selectedView, selectedDate, -1));
  };

  const onNext = () => {
    handleChangeDate(prevnext(selectedView, selectedDate, 1));
  };

  const dateString = jsDateToString(selectedDate, dateFormats.isoDate);
  const label = getDateLabel(selectedView, selectedDate);

  // define styles
  // todo #585 apply style (CalendarWrapper.js and maybe Toolbar.js)
  const dateSelection = (date: Date) => {
    if (isSameDay(date, selectedDate)) {
      return {
        className: WEEK.includes(selectedView)
          ? 'selectedWeek'
          : MONTH.includes(selectedView)
          ? 'selectedMonth'
          : undefined,
      };
    }
  };

  const eventSelection = (
    event: { color: unknown } /* , start, end, isSelected */
  ) => {
    if (event.color) {
      return { style: { backgroundColor: event.color } };
    }
  };

  const selectedShadow = `0px 0px 0px 2px inset var(--chakra-colors-${accent})`;

  const agendaContainerStyle = {
    d: 'flex',
    flexDirection: 'column' as FlexboxProps['flexDirection'],
    h: '100%',
    overflow: 'hidden',
    sx: {
      '.rbc-calendar': { flexGrow: 1 },
      '.rbc-day-slot.selectedWeek': { boxShadow: selectedShadow },
      '.rbc-day-bg.selectedMonth': { boxShadow: selectedShadow },
      '.rbc-month-view, .rbc-time-view': { borderColor },
      '.rbc-header': { borderColor, bg: headerBg },
      '.rbc-time-header-content, .rbc-month-row + .rbc-month-row, .rbc-timeslot-group, .rbc-time-content > * + * > *, .rbc-time-content':
        { borderColor },
      '.rbc-day-bg + .rbc-day-bg, .rbc-day-slot .rbc-time-slot': {
        borderColor,
      },
      '.rbc-off-range-bg': { backgroundColor: headerBg },
      '.rbc-show-more': { bg: 'white', width: '100%' },
      '.rbc-today': { backgroundColor: fillColor },
    },
  };

  return (
    <Box {...agendaContainerStyle}>
      <Grid alignItems="center" templateColumns="repeat(3, 1fr)" mb={2}>
        <GridItem>
          <HStack>
            <FwButton
              small
              leftIcon={'RiArrowLeftSLine'}
              onClick={onPrevious}
            />
            <FwInput
              type={FIELD_TYPE.select}
              value={selectedView}
              options={_.map(
                views,
                (v) =>
                  new Option({
                    key: v,
                    text: t(`calendar|${utils.toSentenceCase(v)}`),
                    value: v,
                  })
              )}
              onChange={(_e, { value }) => handleChangeView(value as string)}
            />
            <FwButton small leftIcon={'RiArrowRightSLine'} onClick={onNext} />
          </HStack>
        </GridItem>
        <GridItem textAlign="center">
          <FwParagraph small>
            {loading && <Spinner as="i" size="sm" mr={1} />}
            {label}
          </FwParagraph>
        </GridItem>
        <GridItem>
          <FwInput
            readOnly={_.includes(readOnlyFilterKeys, agendaFieldKeyFrom)}
            type={FIELD_TYPE.date}
            value={dateString}
            onChange={onChangeDate}
          />
        </GridItem>
      </Grid>
      <Calendar
        popup
        min={min}
        max={max}
        step={step || 15}
        timeslots={slots || 16}
        startAccessor="start"
        endAccessor="end"
        toolbar={false}
        selectable="ignoreEvents"
        culture={_.toLower(language)}
        localizer={localizer}
        events={events}
        date={selectedDate}
        view={selectedView}
        messages={
          _.toLower(language) === _.toLower(LOCALES['fr'])
            ? messagesFrench
            : undefined
        }
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceId"
        resources={resources}
        views={views}
        onDrillDown={handleNavigate}
        onDoubleClickEvent={({ key }) => handleOpen(key)}
        onNavigate={handleNavigate}
        onSelectSlot={(slotInfo: { start: Date }) =>
          handleNavigate(slotInfo.start, selectedView)
        }
        onView={handleChangeView}
        eventPropGetter={eventSelection}
        dayPropGetter={dateSelection}
        components={{
          // wrap calendar event by FwContextMenu
          eventWrapper: (props) => {
            return (
              <EventWrapper
                {...props}
                processes={processes}
                handleOpen={handleOpen}
                handleProcessActionClick={handleProcessActionClick}
                t={t}
              />
            );
          },
        }}
      />
    </Box>
  );
};

FwAgenda.propTypes = {};

export default FwAgenda;
