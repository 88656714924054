import _ from 'lodash';

import { FwStore } from 'components/base';
import {
  computeDateRange,
  Views,
} from 'components/base/containers/mask/agenda/FwMask.Agenda.helpers';
import { getViewDate } from 'components/base/containers/mask/FwMask.helpers';
import { TextObject } from 'components/base/containers/mask/FwMask.structures';
import { Table } from 'core/model';
import { CONTENT_TYPE } from 'core/utils/constant';

const { agenda, timeline } = CONTENT_TYPE;

// update search by input criterion
const setCriterion = (
  criteria: any,
  { key, value }: { key: string; value: string }
) => {
  criteria.searchByInput[key] = value === '' ? undefined : value;
};

// initialize criteria
const getDateRange = (
  date: string | TextObject,
  store: FwStore,
  view?: keyof Views
) => {
  const { rangeString } =
    date && typeof date === 'string' && date.includes('|')
      ? { rangeString: date }
      : computeDateRange(getViewDate(date, store), view);

  return rangeString.split('|');
};

const getDateRangeCriteria = (
  { start, end }: { start?: string[]; end?: string[] },
  [startVal, endVal]: string[]
) => {
  const startField = start?.[0];
  const endField = end?.[0];

  return !startField || !endField || startField === endField
    ? [{ key: startField || endField, value: `${startVal}|${endVal}` }]
    : [
        { key: startField, value: `|${endVal}` },
        { key: endField, value: `${startVal}|` },
      ];
};

const getDateCriteria = (
  { start, end }: { start?: string[]; end?: string[] },
  date: string | TextObject,
  store: FwStore,
  view?: keyof Views
) => getDateRangeCriteria({ start, end }, getDateRange(date, store, view));

const initializeMaskCriteria = (maskData, criteria, overrideFilterData) => {
  const {
    view: { date, type },
    document,
  } = maskData;

  const { start } = document;
  const fieldKeyFrom = start?.[0];
  let selectedDate = date;

  // compute logic for mask filter base on pageStore.filterData
  if (overrideFilterData) {
    _.forOwn(overrideFilterData, (value, key) => {
      if (key === fieldKeyFrom) {
        selectedDate = value;
      } else {
        setCriterion(criteria, { key, value });
      }
    });
  }

  const dateCriteria = getDateCriteria(document, selectedDate, type);

  _.forEach(dateCriteria, (dateCriterion) =>
    setCriterion(criteria, dateCriterion)
  );
};

const initializeTableCriteria = (
  table: Table,
  search: string,
  sort: any,
  filters: any,
  mask: any,
  maskData: any,
  overrideFilterData: any
) => {
  const initCrit: any = { searchByInput: {} };

  // add criteria for filters
  if (filters && filters.length > 0) {
    _.forOwn(filters, (filter) => {
      _.forOwn(filter, (value, key) => {
        setCriterion(initCrit, { key, value });
      });
    });
  }

  // override by pageStore.filterData
  if (overrideFilterData) {
    _.forOwn(overrideFilterData, (value, key) => {
      setCriterion(initCrit, { key, value });
    });
  }

  //// add criteria for user filters
  //if (userFilters && userFilters.length > 0) {
  //  _.forEach(userFilters, userFilter => {
  //    setCriterion(initCrit, userFilter);
  //  });
  //}

  // read sort from args
  const ascendingSort =
    sort && sort.direction
      ? sort.direction === 'ascending'
      : table.ascendingSort;
  const columnSort = sort && sort.key ? sort.key : table.sortByColumnKey;

  // read search from args
  initCrit.searchAllByValue = search;

  // add criteria for sort by default
  initCrit.orderByField = ascendingSort ? columnSort : undefined;
  initCrit.orderByFieldDesc = ascendingSort ? undefined : columnSort;

  // add criteria for table mask
  if (mask) {
    // if mask is agenda
    if (_.includes([agenda, timeline], mask)) {
      initializeMaskCriteria(maskData, initCrit, overrideFilterData);
    }
  }

  return initCrit;
};

export {
  getDateRange,
  getDateRangeCriteria,
  initializeTableCriteria,
  setCriterion,
};
