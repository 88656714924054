import { Divider, InputGroup, useOutsideClick } from '@chakra-ui/react';
import _ from 'lodash';
import { any, bool, InferProps, shape, string } from 'prop-types';
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import autocompleteApi from 'api/form/autocompleteApi';
import { useApi } from 'api/useApi';
import { FwPop, FwSuggestion } from 'components/base';
import { FIELD_TYPE } from 'core/utils/constant';
import getValueMatch from 'core/utils/pattern/getValueMatch';
import utils from 'core/utils/utils';

// import { useAutocompleteFwPop, useStringFwPop } from '../../pop/useFwPop';
import Base from '../base/FwInput.Base';
import { CommonInputProps } from '../FwInput';

const { reference } = FIELD_TYPE;

let timer = null;
const WAITING_INPUT = 500;

const FwAutocomplete: FC<Props & CommonInputProps> = ({
  // direct props
  custom,
  editable,
  fieldID,
  pattern,
  placeholder,
  loading: loadingSuggestions,
  suggestions,
  // common input props
  defaultValue,
  name,
  value,
  referenceValue,
  type,
  clearable,
  disabled,
  readOnly,
  onChange,
  ...props
}) => {
  const defaultText =
    type === reference
      ? (referenceValue || {}).inputValue || defaultValue || ''
      : value || defaultValue || '';

  const dropdownRef = useRef(null);
  const valueRef = useRef(defaultText);
  const selectedSuggestionRef = useRef(defaultText);

  const [text, setText] = useState(defaultText);
  const [suggestionOptions, setSuggestionOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // api
  const [args, setArgs] = useState([]);
  const { fetched: fetchedSuggestions, pending: pendingSuggestions } = useApi(
    custom ? undefined : autocompleteApi.getSuggestions,
    custom ? undefined : args
  );

  //use the useFwPop hook to handle the pop
  // const popProps = {
  //   clearable: clearable,
  //   disabled: disabled,
  //   editable: editable,
  //   name: name,
  //   placeholder: placeholder,
  //   readOnly: readOnly,
  //   saved: undefined,
  //   searchable: undefined,
  //   unchanged: undefined,
  //   onBlur: props.onBlur,
  //   onChange: onChange,
  // };
  // const hookUsage = useAutocompleteFwPop(
  //   {
  //     popProps,
  //   },
  //   {
  //     rightIcon: 'RiSearchLine',
  //     onRightIconClick: null,
  //   }
  // );

  // console.log('hook Usage', hookUsage);

  // clear timer when unmounted
  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (custom) {
      if (suggestions) {
        if (!loadingSuggestions) {
          // pass controlled suggestions to state
          setSuggestionOptions(suggestions.result);
          setLoading(false);
        }
      } else {
        // no suggestions
        setSuggestionOptions([]);
        setLoading(false);
      }
    }
  }, [custom, loadingSuggestions, suggestions]);

  useEffect(() => {
    if (!custom) {
      if (!pendingSuggestions && fetchedSuggestions) {
        const newSuggestions = [];

        if (
          fetchedSuggestions.suggestions &&
          fetchedSuggestions.keyword === valueRef.current.trim()
        ) {
          _.forEach(fetchedSuggestions.suggestions, (suggestion, index) => {
            const { documentID, autoFill } = suggestion;

            newSuggestions.push({
              key: index,
              docid: documentID,
              title: suggestion.value,
              fields: autoFill,
            });
          });
        }

        setLoading(false);
        setSuggestionOptions(newSuggestions);
      }
    }
  }, [custom, pendingSuggestions, fetchedSuggestions]);

  useEffect(() => {
    setText(defaultText);
    valueRef.current = defaultText;
  }, [defaultText]);

  // useEffect(() => {
  //   if (open && text) {
  //     const goodOptions = [];
  //     const otherOptions = [];
  //     _.map(options, (option) => {
  //       if (_.includes(option.title.toLowerCase(), text.toLowerCase())) {
  //         goodOptions.push(option);
  //       } else {
  //         otherOptions.push(option);
  //       }
  //     });
  //     setSuggestionOptions(goodOptions.concat(otherOptions));
  //   }
  // }, [open, options, text]);

  useOutsideClick({
    enabled: open,
    ref: dropdownRef,
    handler: () => {
      // close
      setOpen(false);

      // if input is not editable...
      if (
        editable === false &&
        // ...and current value is different than previous selected...
        valueRef.current !== selectedSuggestionRef.current
      ) {
        // reset local state value
        setText(selectedSuggestionRef.current);

        // and notify parent
        handleChange(undefined, selectedSuggestionRef.current);
      }
    },
  });

  const getSuggestions = (fieldId, fieldValue) => {
    setArgs([fieldId, fieldValue]);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    stringVal: string
  ) => {
    if (onChange) {
      const data = {
        name,
        value:
          type === reference
            ? { ...referenceValue, inputValue: stringVal }
            : stringVal,
      };

      onChange(e, utils.getNameValueFromEData(e, data));
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.currentTarget || e.target;

    let newValue = element.value || '';

    if (!_.isNil(pattern)) {
      const sEn = element.selectionEnd;
      newValue = getValueMatch(pattern, text, element.value, sEn);
    }

    setText(newValue);
    handleChange(e, newValue);
    valueRef.current = newValue;

    if (custom) {
      // set loading state
      setLoading(true);
    } else {
      clearTimeout(timer);

      timer = setTimeout(() => {
        if (newValue) {
          setLoading(true);
          getSuggestions(fieldID, newValue.trim());
        }
      }, WAITING_INPUT);
    }
  };

  const handleClickChange = (e: ChangeEvent, { docid, title, fields }) => {
    setOpen(false);
    setText(title);

    // store selected option
    selectedSuggestionRef.current = title;

    // map auto complete data to newData
    const newData = {};

    newData[name] =
      type === reference
        ? {
            ...referenceValue,
            refDocID: docid,
            inputValue: title,
          }
        : title;

    const refKey = type === reference ? `${name}|` : '';

    // update linked inputs. If type reference then add prefix of ref key
    _.forEach(fields, ({ key, value }) => {
      newData[`${refKey}${key}`] = value;
    });

    // trigger change for all
    onChange?.(e, { name, value: title, fillData: newData });
  };

  const handleInputClick = () => {
    setOpen(true);
  };

  return (
    <FwPop popRef={dropdownRef} open={text ? open : false} autoFocus={false}>
      <FwPop.Anchor>
        {/*input*/}
        <InputGroup>
          <Base
            {...props}
            clearable={clearable}
            disabled={disabled}
            loading={loading}
            name={name}
            pattern={pattern}
            placeholder={placeholder}
            readOnly={readOnly}
            rightIcon="RiSearchLine"
            value={text}
            onChange={handleInputChange}
            onClick={handleInputClick}
            autoComplete={`new-${name}`}
          />
        </InputGroup>
      </FwPop.Anchor>
      <FwPop.Content>
        {/*dropdown menu*/}
        {_.map(suggestionOptions, (suggestionOption, index) => (
          <div key={index}>
            {index > 0 && <Divider my={2} />}
            <FwSuggestion
              title={suggestionOption.title}
              content={_.map(
                suggestionOption.fields,
                utils.getDocDataFromNameValue
              )}
              onClick={(e) => handleClickChange(e, suggestionOption)}
            />
          </div>
        ))}
      </FwPop.Content>
    </FwPop>
  );
};

const propTypes = {
  custom: bool,
  editable: bool,
  fieldID: string,
  loading: bool,
  pattern: string,
  placeholder: string,
  // todo #585 merge with value & collectionValue?
  referenceValue: shape({
    inputValue: string,
    refDocID: string,
    linkDocID: string,
  }),
  suggestions: any,
};

export type Props = InferProps<typeof propTypes> & CommonInputProps;

FwAutocomplete.propTypes = propTypes;

export default FwAutocomplete;
