import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { useFwArea } from 'components/base';
import { Route } from 'core/model';
import utils from 'core/utils/utils';
import TilesPanel from 'scenes/dashboard/components/tiles-panel/TilesPanel';

import CustomPage from './CustomPage';
import { getBasePath, updateURL, getInitStepID } from './Steps.helpers';

const Steps = ({
  steps,
  documentID,
  docData,
  docNumber,
  invalidInputKey,
  invalidTrigger,
  invalidStepID,
  loadingInputKeys,
  onChangeData,
  onInputBlur,
}: any) => {
  // get the step ID from the url (if there's one)
  const { stepId } = useParams<{ stepId: string }>();
  const routesRef = useRef<Route[]>();
  const [activeStepID, setActiveStepID] = useState(
    getInitStepID(stepId, steps)
  );

  // retrieve the current area in order to get the right base path
  const { area } = useFwArea();

  // update URL when step changes
  useEffect(() => {
    const basePath = getBasePath(
      area?.key != null && area?.key != undefined && area?.key != ''
    );
    updateURL(basePath, activeStepID);
  }, [activeStepID, area]);

  const handleChange = useCallback((e, stepID) => {
    routesRef.current = _.map(routesRef.current, (r) => ({
      ...r,
      active: r.key === stepID,
    }));
    setActiveStepID(stepID);
  }, []);

  // change step when invalidInputKey changes and set invalidInputKeyState so CustomPage can scroll to the invalid input
  useEffect(() => {
    if (invalidStepID) {
      if (invalidTrigger?.timeStamp) {
        // move to step
        handleChange(null, invalidStepID);
      }
    }
  }, [invalidStepID, invalidTrigger, steps, handleChange]);

  const activeStep = _.find(steps, { stepID: activeStepID });

  if (!routesRef.current && steps && steps.length > 1) {
    routesRef.current = _.map(steps, ({ stepID, name, design }) => ({
      key: stepID,
      name,
      active: activeStep.stepID === stepID,
      design,
      handleChangeTile: (e) => {
        handleChange(e, stepID);
      },
    }));
  }

  const visibleContents = utils.filterExistent(activeStep.contents);

  return (
    <>
      {routesRef.current && (
        <TilesPanel routes={routesRef.current} small scroll />
      )}
      {activeStep && (
        <CustomPage
          docData={docData}
          documentID={documentID}
          docNumber={docNumber}
          invalidInputKey={
            activeStepID === invalidStepID ? invalidInputKey : undefined
          }
          invalidTrigger={invalidTrigger}
          loadingInputKeys={loadingInputKeys}
          pageContents={visibleContents}
          onChangeData={onChangeData}
          onInputBlur={onInputBlur}
        />
      )}
    </>
  );
};

// steps.propTypes = {
//   steps: arrayOf(object).isRequired,
// };

export default React.memo(Steps);
