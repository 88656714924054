import {
  chakra,
  Fade,
  HStack,
  Spacer,
  useBreakpointValue,
} from '@chakra-ui/react';
import _ from 'lodash';
import { arrayOf, bool, func, InferProps, node, string } from 'prop-types';
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import {
  FwButton,
  FwPancake,
  FwParagraph,
  useFwModule,
  useFwPage,
} from 'components/base';

import FwDimmer from './dimmer/FwDimmer';
import { getElement } from './FwDashboard.helpers';
import FwSidebar, {
  getSidebarWidth,
  mvmtCurve,
  Props as SidebarProps,
} from './sidebar/FwSidebar';

const StyledFade = chakra(Fade);

// todo wip#125 refactor
interface State {
  compact?: boolean;
  showSidebar?: boolean;
}

// todo wip#125 refactor
const reducer = (state: State, action: State) => {
  return { ...state, ..._.pickBy(action, (value) => value !== undefined) };
};

const FwDashboard: FC<Props> & {
  Sidebar: FC<SidebarProps>;
  Content: FC<ContentProps>;
} = ({ children, compactSidebar = false }) => {
  const isMobileRef = useRef(true);

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  // sidebar state
  const showSidebarDefault = useBreakpointValue({
    base: false,
    sm: true,
  });

  const [{ showSidebar, compact }, dispatch] = useReducer(reducer, {
    showSidebar: showSidebarDefault,
  });

  useEffect(() => {
    dispatch({ compact: compactSidebar });
  }, [compactSidebar]);

  useEffect(() => {
    dispatch({ showSidebar: showSidebarDefault });
  }, [showSidebarDefault]);

  const toggleSidebar = useCallback(() => {
    const toggleValue = !showSidebar;
    dispatch({ showSidebar: toggleValue });
  }, [showSidebar]);

  // module watch
  const { module } = useFwModule();
  const [page] = useFwPage();

  useEffect(() => {
    isMobileRef.current = isMobile;
  }, [isMobile]);

  useEffect(() => {
    if (isMobileRef.current) {
      dispatch({ showSidebar: false });
    }
  }, [module, page]);

  // styles
  const defaultWidth = '100%';

  const dashboardStyle = {
    h: '100%',
    w:
      showSidebar && isMobile
        ? `calc(${defaultWidth} + ${getSidebarWidth(compact)})`
        : defaultWidth,
    transition: `width ${mvmtCurve}`,
    overflow: 'hidden',
    spacing: 0,
  };

  // render
  return (
    <HStack {...dashboardStyle}>
      {React.Children.map(children, (child, index) => {
        return getElement(
          child as ReactNode,
          index === 0
            ? { compact, open: showSidebar }
            : index === 1
            ? { toggleSidebar }
            : {}
        );
      })}
      {
        // only show animation for fade in (no fade out)
        isMobile && showSidebar && (
          <StyledFade zIndex={5} in={showSidebar}>
            <FwDimmer onClick={toggleSidebar} />
          </StyledFade>
        )
      }
    </HStack>
  );
};

const FwDashboardContent: FC<ContentProps> = ({
  buttons,
  children,
  title,
  toggleSidebar,
}) => {
  return (
    <FwPancake
      header={
        <HStack>
          <FwButton small leftIcon="RiMenu2Fill" onClick={toggleSidebar} />
          <FwParagraph small>{title}</FwParagraph>
          <Spacer />
          {buttons}
        </HStack>
      }
    >
      {children}
    </FwPancake>
  );
};

const contentPropTypes = {
  buttons: node,
  children: node,
  toggleSidebar: func,
  title: string,
};

type ContentProps = InferProps<typeof contentPropTypes>;

FwDashboard.Sidebar = FwSidebar;
FwDashboard.Content = FwDashboardContent;

const propTypes = {
  children: arrayOf((prop, index, componentName) => {
    let error: Error;
    const childrenCount = React.Children.count(prop);

    if (childrenCount !== 2) {
      error = new Error(`${componentName} only supports having two children`);
    } else {
      // make sure children are composed of, first, 1 Sidebar then 1 Content
      const expectedTypes = [FwSidebar.name, FwDashboardContent.name];
      const idx = parseInt(index);

      if (prop[index].type.name !== expectedTypes[idx]) {
        error = new Error(
          `${componentName} ${
            idx === 0 ? 'first' : 'second'
          } child component must be of type ${expectedTypes[idx]}`
        );
      }
    }

    return error;
  }).isRequired,
  compactSidebar: bool,
};

export type Props = InferProps<typeof propTypes>;

FwDashboard.propTypes = propTypes;

export default FwDashboard;
