import _ from 'lodash';
import { RouteChildrenProps } from 'react-router';

import {
  BatchTemplate,
  Column,
  FwSettingsProviderProps,
  Template,
} from 'core/model';

// local storage
// available for whole browser with restriction on domain (no cors allowed)

// session storage
// available for a single tab with restriction on domain (no cors allowed)
// flushed when tab is closed

export const STORAGE_KEYS = {
  fallbackUrl: 'fallbackUrl',
  lastCustomPage: 'lastCustomPage',
  processes: 'processes',
  settings: 'settings',
  tables: 'tables',
  templates: 'templates',
  theme: 'chakra-ui-color-mode',
  token: 'token',
};

// general
export const clearStorage = () => {
  resetToken();
  resetAllTableCache();
  resestFallbackUrl();
  resetTemplates();
  resetProcesses();
};

export const clearStorageAndRedirect = (
  path: string,
  history?: RouteChildrenProps['history'],
  forceRefresh = true
) => {
  clearStorage();

  if (forceRefresh) {
    // redirect and fetch page from site server (refresh)
    window.location.href = path;
  } else {
    // redirect without refresh
    history.push(path);
  }
};

const setLocalStorage = (key: string, value: string) => {
  if (!_.isNil(value)) {
    localStorage.setItem(key, value);
  } else {
    localStorage.removeItem(key);
  }
};

// app default values
export const getFallbackUrl = () => {
  return localStorage.getItem(STORAGE_KEYS.fallbackUrl);
};

export const setFallbackUrl = (fallbackUrl: string) => {
  setLocalStorage(STORAGE_KEYS.fallbackUrl, fallbackUrl);
};

export const resestFallbackUrl = () => {
  localStorage.removeItem(STORAGE_KEYS.fallbackUrl);
};

// token
export const getToken = () => {
  return localStorage.getItem(STORAGE_KEYS.token);
};

export const setToken = (value: string) => {
  setLocalStorage(STORAGE_KEYS.token, value);
};

export const resetToken = () => {
  localStorage.removeItem(STORAGE_KEYS.token);
};

// table filters and sort
export type TableFilters = { [x: string]: string };

export type TableSort = { key: string; direction: string };

export type TableCache = {
  filters?: TableFilters;
  search?: string;
  sort?: TableSort;
};

export const getAllTableCache = () => {
  let parsedCache: TableCache = {};
  const cache = localStorage.getItem(STORAGE_KEYS.tables);

  if (cache) {
    parsedCache = JSON.parse(cache);
  }

  return parsedCache;
};

const setAllTableCache = (cache: TableCache) => {
  setLocalStorage(STORAGE_KEYS.tables, JSON.stringify(cache));
};

export const resetAllTableCache = () => {
  localStorage.removeItem(STORAGE_KEYS.tables);
};

export const getTableCache = (
  tableKey: string,
  currentCache = getAllTableCache()
): TableCache => {
  return (tableKey && currentCache[tableKey]) || { filters: {} };
};

export const resetTableCache = (
  tableKey: string,
  currentCache = getAllTableCache()
) => {
  currentCache[tableKey] = undefined;
  setAllTableCache(currentCache);
};

const updateTableCacheProps = (tableKey: string, updatedProps: TableCache) => {
  if (tableKey) {
    const currentCache = getAllTableCache();
    const tableCache = getTableCache(tableKey, currentCache);

    const newCache = {
      ...currentCache,
      [tableKey]: _.merge(tableCache, updatedProps),
    };

    setAllTableCache(newCache);
  }
};

export const cacheTableSearch = (tableKey: string, value: string) => {
  updateTableCacheProps(tableKey, { search: value || null });
};

export const cacheTableSort = (
  tableKey: string,
  key: string,
  direction: string
) => {
  updateTableCacheProps(tableKey, { sort: { key, direction } });
};

export const cacheTableFilter = (tableKey: string, { key, value }: Column) => {
  const valueIsArrayOrString = _.isArray(value) || _.isString(value);
  const newValue =
    !_.isNil(value) &&
    ((valueIsArrayOrString && value.length > 0) || !valueIsArrayOrString)
      ? value
      : null;

  updateTableCacheProps(tableKey, { filters: { [key]: newValue } });
};

// theme
export const getTheme = () => {
  return _.toUpper(localStorage.getItem(STORAGE_KEYS.theme));
};

export const setTheme = (value: string) => {
  setLocalStorage(STORAGE_KEYS.theme, _.toLower(value));
};

// settings
export const readSettings = () => {
  const value = localStorage.getItem(STORAGE_KEYS.settings);
  return value ? new FwSettingsProviderProps(JSON.parse(value)) : null;
};

export const storeSettings = (settings: FwSettingsProviderProps) => {
  setLocalStorage(STORAGE_KEYS.settings, JSON.stringify(settings));
};

// templates
export const readTemplates = () => {
  const value = localStorage.getItem(STORAGE_KEYS.templates);
  return value
    ? _.map(JSON.parse(value), (tmplt) => new Template(tmplt))
    : null;
};

export const storeTemplates = (array: Template[]) => {
  setLocalStorage(STORAGE_KEYS.templates, JSON.stringify(array));
};

export const resetTemplates = () => {
  localStorage.removeItem(STORAGE_KEYS.templates);
};

// processes (batch templates)
export const readProcesses = () => {
  const value = localStorage.getItem(STORAGE_KEYS.processes);
  return value ? _.map(JSON.parse(value), (bt) => new Template(bt)) : null;
};

export const storeProcesses = (array: BatchTemplate[]) => {
  setLocalStorage(STORAGE_KEYS.processes, JSON.stringify(array));
};

export const resetProcesses = () => {
  localStorage.removeItem(STORAGE_KEYS.processes);
};

// last custom page
export const getLastCustomPage = () => {
  const value = localStorage.getItem(STORAGE_KEYS.lastCustomPage);
  return value;
};

export const setLastCustomPage = (path: string) => {
  setLocalStorage(STORAGE_KEYS.lastCustomPage, path);
};
